/* Main container */
.error-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Updated background color */
  background-color: #f3f4f6; /* Tailwind's bg-gray-100 */
  color: #1f2937; /* Tailwind's text-gray-900 */
}

/* Dark mode */
.error-page.dark-mode {
  background-color: #111827; /* Tailwind's bg-gray-900 */
  color: #ffffff; /* Tailwind's text-white */
}

/* Error content container */
.error-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  /* Updated background color */
  background-color: #ffffff; /* Tailwind's bg-white */
  color: inherit;
}

/* Dark mode for error container */
.error-page.dark-mode .error-container {
  background-color: #1f2937; /* Tailwind's bg-gray-800 */
}

/* Error title */
.error-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

/* Error message */
.error-message {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

/* Error code */
.error-code {
  font-size: 1rem;
  color: #6b7280; /* Tailwind's text-gray-500 */
  margin-bottom: 1rem;
}

/* Note message */
.note-message {
  color: #dc2626; /* Tailwind's text-red-600 */
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

/* Back button */
.back-button {
  background-color: #3b82f6; /* Tailwind's bg-blue-500 */
  color: #ffffff;
  font-size: 1rem;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 1.5rem;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #2563eb; /* Tailwind's bg-blue-600 */
}

/* Dark mode for back button */
.error-page.dark-mode .back-button {
  background-color: #2563eb; /* Tailwind's bg-blue-600 */
}

.error-page.dark-mode .back-button:hover {
  background-color: #1d4ed8; /* Tailwind's bg-blue-700 */
}

/* Contact support */
.contact-support {
  font-size: 1rem;
  color: inherit;
}

/* Support link */
.support-link {
  color: #3b82f6; /* Tailwind's text-blue-500 */
  text-decoration: none;
  font-weight: 500;
}

.support-link:hover {
  text-decoration: underline;
}

/* Dark mode for support link */
.error-page.dark-mode .support-link {
  color: #60a5fa; /* Tailwind's text-blue-400 */
}

/* Footer */
.error-footer {
  background-color: #e5e7eb; /* Tailwind's bg-gray-200 */
  text-align: center;
  padding: 1rem 0;
  color: #6b7280; /* Tailwind's text-gray-500 */
}

.error-footer p {
  margin: 0;
  font-size: 0.9rem;
}

/* Dark mode footer */
.error-page.dark-mode .error-footer {
  background-color: #374151; /* Tailwind's bg-gray-700 */
  color: #9ca3af; /* Tailwind's text-gray-400 */
}

/* Responsive design */
@media (max-width: 600px) {
  .error-title {
    font-size: 2rem;
  }
  .error-message {
    font-size: 1.1rem;
  }
}

