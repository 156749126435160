.image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

img {
    width: 70px;
    height: 70px;
}

.text {
    font-size: 20px;
    font: bolder;
    margin-top: 20px;
    color:color(from color srgb r g b)
}
