/* Base tab styles */
.ant-tabs {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.ant-tabs-tab {
  padding: 12px 16px;
  margin: 0 2px 0 0;
  font-size: 14px;
  transition: color 0.2s;
  cursor: pointer;
  background-color: #d4d4d4;
  border: 2px solid #d0d0d0;
  border-bottom: 2px solid #1fe8e3;
  border-radius: 4px 4px 0 0;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  @apply mr-1 !important;
}

.ant-tabs-tab:hover {
  color: #1890ff;
}

.ant-tabs-tab-btn {
  outline: none;
}

.ant-tabs-ink-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background: #1890ff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/* Icon and label spacing */
.ant-tabs-tab .anticon {
  margin-right: 8px;
  font-size: 14px;
}

/* Active tab styles */
.ant-tabs-tab-active {
  color: #1890ff;
  font-weight: 500;
}

/* Tab nav styles */
.ant-tabs-nav {
  position: relative;
  display: flex;
  border-bottom: 1px solid #6a6a6a;
  transition: padding 0.3s;
  margin-bottom: 16px;
}

.ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0;
  content: '';
}


/* Light mode: Active card tab */
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
:where(.css-dev-only-do-not-override-qnu6hi).ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  @apply bg-white border-gray-300 !important;
}

/* Light mode: Active tab text */
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn,
:where(.css-dev-only-do-not-override-qnu6hi).ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  @apply text-blue-600 !important;
}

/* Light mode: Tab navigation bar */
.ant-tabs-card > .ant-tabs-nav,
:where(.css-dev-only-do-not-override-qnu6hi).ant-tabs-card > .ant-tabs-nav {
  @apply border-b border-gray-300 !important;
}

/* Light mode: Tab hover state */
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab:hover,
:where(.css-dev-only-do-not-override-qnu6hi).ant-tabs-card > .ant-tabs-nav .ant-tabs-tab:hover {
  @apply bg-gray-100 !important;
}

/* Dark mode styles */
.dark .ant-tabs-tab {
  color: rgba(255, 255, 255, 0.65);
}

.dark .ant-tabs-tab:hover {
  color: #40a9ff;
}

.dark .ant-tabs-tab-active {
  color: #40a9ff;
}

.dark .ant-tabs-nav::before {
  border-bottom-color: #303030;
}

.dark .ant-tabs-ink-bar {
  background: #40a9ff;
}

/* Dark mode: Card tab styles */
.dark .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
:where(.css-dev-only-do-not-override-qnu6hi).ant-tabs-card.dark > .ant-tabs-nav .ant-tabs-tab {
  @apply bg-gray-700 text-gray-400 border-gray-600 !important;
}

/* Dark mode: Active card tab */
.dark .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
:where(.css-dev-only-do-not-override-qnu6hi).ant-tabs-card.dark > .ant-tabs-nav .ant-tabs-tab-active {
  @apply bg-gray-800 border-gray-600 !important;
}

/* Dark mode: Active tab text */
.dark .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn,
:where(.css-dev-only-do-not-override-qnu6hi).ant-tabs-card.dark > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  @apply text-blue-500 !important;
}

/* Dark mode: Tab navigation bar */
.dark .ant-tabs-card > .ant-tabs-nav,
:where(.css-dev-only-do-not-override-qnu6hi).ant-tabs-card.dark > .ant-tabs-nav {
  @apply border-b border-gray-600 !important;
}

/* Dark mode: Tab hover state */
.dark .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab:hover,
:where(.css-dev-only-do-not-override-qnu6hi).ant-tabs-card.dark > .ant-tabs-nav .ant-tabs-tab:hover {
  @apply bg-gray-600 !important;
}

/* Animation styles */
.ant-tabs-content {
  display: flex;
  transition: margin-left 0.3s;
}

.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}

.ant-tabs-content-animated {
  transition: margin 0.3s;
}

/* Large size styles */
.ant-tabs-large .ant-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
  background: #b4b4b4;
}

/* Disabled tab styles */
.ant-tabs-tab-disabled {
  cursor: not-allowed;
  color: rgba(0, 0, 0, 0.25);
}

.dark .ant-tabs-tab-disabled {
  color: rgba(255, 255, 255, 0.25);
}

/* RTL support */
[dir='rtl'] .ant-tabs-tab {
  margin: 0 0 0 20px;
}

[dir='rtl'] .ant-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 8px;
}

/* Tab component layout and sizing */
:where(.css-dev-only-do-not-override-qnu6hi).ant-tabs {
  @apply flex flex-col h-full w-full !important;
  min-height: unset !important;
}

/* Tab content holder dimensions */
:where(.css-dev-only-do-not-override-qnu6hi).ant-tabs .ant-tabs-content-holder {
  @apply h-full w-full !important;
  height: calc(100% - 46px) !important;
}

/* Tab content panel dimensions */
:where(.css-dev-only-do-not-override-qnu6hi).ant-tabs .ant-tabs-content {
  @apply h-full w-full !important;
  height: 100% !important;
}

/* Individual tab pane dimensions */
:where(.css-dev-only-do-not-override-qnu6hi).ant-tabs .ant-tabs-tabpane {
  @apply h-full w-full !important;
  height: 100% !important;
}

/* Active tab pane dimensions */
:where(.css-dev-only-do-not-override-qnu6hi).ant-tabs .ant-tabs-tabpane-active {
  @apply h-full w-full !important;
  height: 100% !important;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .ant-tabs-tab {
    margin: 0 16px 0 0;
    padding: 8px 0;
  }

  .ant-tabs-large .ant-tabs-tab {
    padding: 12px 0;
    font-size: 14px;
  }
}
