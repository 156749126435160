 /* Card styles */
.table-card.dark-card {
  background-color: #374151 !important; /* gray-700 */
  border-color: #4B5563 !important; /* gray-600 */
}

.table-card.dark-card .ant-card-body {
  background-color: #374151 !important; /* gray-700 */
}

/* Table styles */
.filterable-table.dark-mode {
  background-color: #374151 !important; /* gray-700 */
  color: #F3F4F6 !important; /* gray-100 */
}

.filterable-table.dark-mode .ant-table {
  background-color: #374151 !important; /* gray-700 */
  color: #F3F4F6 !important; /* gray-100 */
}

.filterable-table.dark-mode .ant-table-thead > tr > th {
  background-color: #1F2937 !important; /* gray-800 */
  color: #D1D5DB !important; /* gray-300 */
  border-bottom: 1px solid #4B5563 !important; /* gray-600 */
}

.filterable-table.dark-mode .ant-table-tbody > tr > td {
  background-color: #374151 !important; /* gray-700 */
  color: #F3F4F6 !important; /* gray-100 */
  border-bottom: 1px solid #4B5563 !important; /* gray-600 */
}

.filterable-table.dark-mode .ant-table-tbody > tr:hover > td {
  background-color: #4B5563 !important; /* gray-600 */
}

.filterable-table.dark-mode .ant-empty-description {
  color: #F3F4F6 !important; /* gray-100 */
}

/* Input styles */
.dark-input {
  background-color: #374151 !important; /* gray-700 */
  color: #F3F4F6 !important; /* gray-100 */
  border-color: #4B5563 !important; /* gray-600 */
}

.dark-input::placeholder {
  color: #D1D5DB !important; /* gray-300 */
}

.dark-input:hover,
.dark-input:focus {
  border-color: #60A5FA !important; /* blue-400 for better visibility */
  background-color: #374151 !important; /* gray-700 */
}

/* Row styles */
.clickable-row {
  cursor: pointer;
}

.filterable-table.dark-mode .ant-table-tbody > tr.clickable-row:hover > td {
  background-color: #4B5563 !important; /* gray-600 */
}
