@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Main page container styling */
  .page-container {
    @apply bg-gray-200 dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden p-6;
  }

  /* Header component layout */
  .component-header {
    @apply flex justify-between items-center mb-4;
  }

  /* Title styling for components */
  .component-title {
    @apply text-xl font-semibold text-gray-800 dark:text-white;
  }

  /* Card component base styles */
  .component-card {
    @apply bg-white dark:bg-gray-700 shadow rounded-lg overflow-hidden border-0 w-full;
  }

  /* Card header styles */
  .component-card :where(.ant-card-head) {
    @apply bg-gray-800 dark:bg-gray-800 border border-gray-200 dark:border-gray-600 dark:text-white rounded-t-lg;
  }

  /* Card body styles */
  .component-card :where(.ant-card-body) {
    @apply border border-gray-200 dark:border-gray-600 border-t-0 rounded-b-lg p-0 overflow-x-auto;
  }

  /* Form spacing */
  .component-form {
    @apply space-y-4;
  }

  /* Form label styling */
  .component-label {
    @apply text-gray-800 dark:text-gray-200;
  }

  /* Input field styling */
  .component-input {
    @apply mt-1 block w-full rounded-md border-gray-300 shadow-sm
           focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50
           dark:bg-gray-800 dark:border-gray-600 dark:text-white;
  }

  .component-input:disabled {
    @apply bg-gray-100 text-gray-600 cursor-not-allowed
           dark:bg-gray-600 dark:text-gray-200 dark:border-gray-600;
  }

  /* Select component dropdown background in dark mode */
  .ant-select-dropdown.dark-mode {
    @apply bg-gray-800 !important;
  }

  .ant-select-dropdown.dark-mode .ant-select-item {
    @apply text-white !important;
  }

  .ant-select-dropdown.dark-mode .ant-select-item-option-active {
    @apply bg-gray-700 !important;
  }

  .ant-select-dropdown.dark-mode .ant-select-item-option-selected {
    @apply bg-gray-600 !important;
  }

  /* Form input and textarea background in dark mode */
  .component-input.dark-mode {
    @apply bg-gray-800 border-gray-600 text-white !important;
  }

  .component-input.dark-mode::placeholder {
    @apply text-gray-400 !important;
  }

  /* Select component background in dark mode */
  .component-select.dark-mode .ant-select-selector {
    @apply bg-gray-800 border-gray-600 text-white !important;
  }

  /* TextArea component background in dark mode */
  textarea.component-input.dark-mode {
    @apply bg-gray-800 border-gray-600 text-white !important;
  }

  /* Base button styles */
  .component-button {
    @apply px-4 py-1 rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 transition-colors duration-200;
  }

  /* Primary button variant */
  .component-button-primary {
    @apply bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500
           dark:bg-blue-500 dark:hover:bg-blue-600;
  }

  /* Secondary button variant */
  .component-button-secondary {
    @apply bg-white text-gray-700 hover:bg-gray-50 focus:ring-gray-500
           dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600;
  }

  /* Table base styles */
  .component-table {
    @apply w-full overflow-x-auto;
  }

  /* Table container styles */
  .component-table :where(.ant-table) {
    @apply w-full border border-gray-100 dark:border-gray-800 !important;
  }

  /* Table wrapper */
  .component-table .ant-table-wrapper {
    @apply w-full overflow-x-auto;
  }

  /* Table container */
  .component-table .ant-table-container {
    @apply w-full;
  }

  /* Table content */
  .component-table .ant-table-content {
    @apply w-full overflow-x-auto;
  }

  /* Table header cell styles */
  .component-table :where(.ant-table-thead > tr > th) {
    @apply bg-white dark:bg-gray-800 text-black dark:text-white border-2 border-gray-800 dark:border-gray-800 !important;
  }

  /* Table body cell styles */
  .component-table :where(.ant-table-tbody > tr > td) {
    @apply bg-white dark:bg-gray-700 text-gray-800 dark:text-gray-200 !important;
  }

  /* Remove default table cell pseudo-element */
  .component-table th.ant-table-cell::before {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }

  /* Table cell borders */
  .component-table :where(.ant-table-thead > tr > th),
  .component-table :where(.ant-table-tbody > tr > td) {
    @apply border-gray-200 dark:border-gray-600 !important;
  }

  /* Table row hover effect */
  .component-table :where(.ant-table-tbody > tr:hover > td) {
    @apply bg-gray-50 dark:bg-gray-700 !important;
  }


  /* Value text styling */
  .vm-value-text {
    @apply text-gray-800 dark:text-gray-200;
  }

  /* Back to top button */
  .back-to-top-button {
    @apply h-10 w-10 rounded bg-blue-500 hover:bg-blue-600 text-white flex items-center justify-center cursor-pointer;
    transition: all 0.3s;
  }

  /* Dark mode back to top button */
  .dark .back-to-top-button {
    @apply bg-blue-600 hover:bg-blue-700;
  }

  /* Phone input dark mode styles */
  .dark-mode-phone {
    background-color: #374151 !important;
    border-color: #4B5563 !important;
    color: white !important;
  }

  /* Dropdown dark mode styles */
  .dark-mode-dropdown {
    background-color: #374151 !important;
    color: white !important;
  }

  .dark-mode-dropdown .country {
    color: white !important;
  }

  /* Tag component styles */
  .component-tag.dark-mode {
    background-color: #3B82F6;
    color: white;
  }
  .component-tag {
    background-color: #3B82F6;
    color: white;
  }

  /* Select component styles */
  .ant-select-selector {
    @apply dark:bg-gray-800 dark:border-gray-600 !important;
  }

  .ant-select-selection-item {
    @apply text-gray-800 dark:text-white !important;
  }

  /* Dropdown menu styles */
  .dark-mode-select-dropdown {
    @apply bg-gray-800 border-gray-600 !important;
  }

  .dark-mode-select-dropdown .ant-select-item {
    @apply text-white !important;
  }

  .dark-mode-select-dropdown .ant-select-item-option-active {
    @apply bg-gray-700 !important;
  }

  .dark-mode-select-dropdown .ant-select-item-option-selected {
    @apply bg-gray-600 !important;
  }

  .dark-mode-select-dropdown .ant-select-item:hover {
    @apply bg-gray-700 !important;
  }

  .ant-select-item-option-selected {
    @apply dark:bg-gray-700 !important;
  }

  .ant-select-item-option-active {
    @apply dark:bg-gray-700 !important;
  }

  /* Tab content wrapper */
  .ant-tabs-content-holder {
    @apply w-full overflow-x-auto;
  }

  .ant-tabs-tabpane {
    @apply w-full;
  }
  .ant-tabs .ant-tabs-tabpane {
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Phone input component styling */
  .react-tel-input {
    @apply mb-2;
  }

  .dark-mode .react-tel-input .selected-flag:hover,
  .dark-mode .react-tel-input .selected-flag.open {
    @apply bg-gray-700;
  }


  .dark-mode .react-tel-input .country-list .country {
    @apply hover:bg-gray-700;
  }

  .dark-mode .react-tel-input .country-list .country.highlight {
    @apply bg-gray-700;
  }

  /* Form select component styles */
  .component-form .ant-select {
    @apply w-full;
  }

  .component-form .ant-select-selector {
    @apply dark:bg-gray-800 dark:border-gray-600 dark:text-white !important;
    @apply rounded-md border-gray-300 shadow-sm;
  }

  /* Dark mode input styles */
  .dark-mode .component-input::placeholder {
    @apply text-gray-400;
  }

  .dark-mode .component-input {
    @apply text-white;
  }

  /* Account code copy button container */
  .component-form-item .relative {
    @apply w-full;
  }

  .component-form-item .relative input {
    @apply pr-10;
  }

  .component-form-item .relative button {
    @apply bg-transparent border-0 p-0;
  }

  /* Phone input flag dropdown styles */
  .react-tel-input .flag-dropdown {
    background: transparent !important;
    border-right: 1px solid #d1d5db !important;
    width: 75px !important;
  }

  .react-tel-input .selected-flag {
    width: 75px !important;
    padding: 0 0 0 8px !important;
    height: 36px !important;
  }


  .dark-mode .react-tel-input .flag-dropdown {
    border-right-color: #4B5563 !important;
  }

  .dark-mode .react-tel-input .selected-flag {
    @apply bg-gray-800;
  }

  /* Country list dropdown styles */
  .react-tel-input .country-list {
    @apply text-gray-800 border border-gray-300;
    margin: 2px 0 0 -1px !important;
  }


  /* Visual analytics tab styles */
  .va-tab-container {
    @apply page-container;
  }

  .va-tab-card {
    @apply component-card;
  }

  .va-tab-header {
    @apply component-header;
  }

  .va-tab-title {
    @apply component-title;
  }

  .va-tab-table {
    @apply component-table;
  }

  .va-tab-button {
    @apply component-button component-button-primary;
  }
}
